<template>
  <div class="container-fluid filterItem">
    <div class="row">
      <template v-if="data.type === 'S'">
        <div class="col-3 d-flex align-items-center">
          <el-checkbox-group v-model="checked">
            <el-checkbox :label="data.label">{{ $t(data.label) }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="col-9">
          <el-select
              :disabled="!checked"
              :multiple="data.options && data.options.multiple"
              :placeholder="$t('system.select')"
              :size="fieldsSize"
              :value="selectedFilterMultiple"
              class="w-100"
              filterable
              v-if="data.options && data.options.multiple"
              v-model="selectedFilterMultiple"
          >
            <el-option
                :key="option.id"
                :label="$t(option.label)"
                :value="option.id"
                v-for="option in data.values"
            >
            </el-option>
          </el-select>
          <el-select
              :disabled="!checked"
              :placeholder="$t('system.select')"
              :size="fieldsSize"
              :value="selectedFilter"
              class="w-100"
              filterable
              v-else
              v-model="selectedFilter"
          >
            <el-option
                :key="option.id"
                :label="$t(option.label)"
                :value="option.id"
                v-for="option in data.values"
            >
            </el-option>
          </el-select>
        </div>
      </template>
      <template v-else-if="data.type === 'D'">
        <div class="col-3 d-flex align-items-center">
          <el-checkbox-group v-model="checked">
            <el-checkbox :label="data.label">{{ $t(data.label) }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="col-9">
          <el-date-picker
              :disabled="!checked"
              :picker-options="pickerOptions"
              :size="fieldsSize"
              class="w-100"
              format="yyyy-MM-dd"
              type="daterange"
              v-model="selectedFilterRange"
          >
          </el-date-picker>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import {mapGetters} from 'vuex';
import projectTreeBuilder from '../../mixin/projectsTreeBuilder';

export default {
  name: 'dynamicFilters',
  mixins: [projectTreeBuilder],
  props: {
    data: {},
  },
  data() {
    return {
      fieldsSize: 'mini',
      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: this.$t('date.today'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: this.$t('date.last_week'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: this.$t('date.last_month'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: this.$t('date.last_three_month'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          },
        }],
      },
    };
  },
  computed: {
    ...mapGetters('filters', ['getIssueFilterValue', 'getIssueFilterIsActive', 'getIssueFilterValueMulti']),
    selectedFilter: {
      get() {
        return this.getIssueFilterValue({name: this.data.label, idTracker: this.$route.params.idTracker});
      },
      set(newVal) {
        this.$store.commit('filters/SET_ISSUE_FILTER_VALUE', {
          filter: this.data.label,
          value: newVal,
          idTracker: this.$route.params.idTracker,
        });
      },
    },
    selectedFilterMultiple: {
      get() {
        return this.getIssueFilterValueMulti({name: this.data.label, idTracker: this.$route.params.idTracker});
      },
      set(newVal) {
        this.$store.commit('filters/SET_ISSUE_FILTER_VALUE', {
          filter: this.data.label,
          value: newVal,
          idTracker: this.$route.params.idTracker,
        });
      },
    },
    checked: {
      get() {
        return this.getIssueFilterIsActive({name: this.data.label, idTracker: this.$route.params.idTracker});
      },
      set(newVal) {
        this.$store.commit('filters/SET_ISSUE_FILTER_TOOGLE', {
          filter: this.data.label,
          value: newVal,
          idTracker: this.$route.params.idTracker,
        });
      },
    },
    selectedFilterRange: {
      get() {
        return this.getIssueFilterValue({name: this.data.label, idTracker: this.$route.params.idTracker});
      },
      set(newVal) {
        let dateRange = newVal;
        if (dateRange) {
          dateRange = dateRange.map(item => moment(item).format('YYYY-MM-DD'));
        }
        this.$store.commit('filters/SET_ISSUE_FILTER_VALUE', {
          filter: this.data.label,
          value: dateRange,
          idTracker: this.$route.params.idTracker,
        });
      },
    },
  },
  beforeMount() {
    if (this.data.label === 'projects.id') {
      this.data.values = this.buildProjectTree(this.data.values, 'label');
    }
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.filterItem {
  padding: 10px 0 0 0;

  &:first-child {
    padding: 0
  }
}
</style>
