export default {
  methods: {
    buildProjectTree(projectsTree, label = 'name') {
      let outputTree = [];
      for (let i = 0; i < projectsTree.length; i++) {
        let level = 0;
        for (let j = 0; j < projectsTree[i].length; j++) {
          if (j > 0) {
            //compare levels with previous node
            if ((projectsTree[i][j - 1].level < projectsTree[i][j].level)) {
              level++;
            }
            if ((projectsTree[i][j - 1].level > projectsTree[i][j].level)) {
              level = projectsTree[i][j].level;
            }
          }
          outputTree.push({
            [label]: this.createLevelName(projectsTree[i][j].name, level),
            id: projectsTree[i][j].id,
          });
        }
      }
      return outputTree;
    },
    createLevelName(name, level) {
      let prefix = '';
      for (let i = 0; i < level; i++) {
        prefix = prefix + '>';
      }
      return prefix + ' ' + name;
    },
  },
};
